import { NavigateFunction } from 'react-router-dom'
import { DateRange } from 'mui-daterange-picker'
import { format } from 'date-fns'

export function getApiUrl() {
  if (window.location.href.indexOf('http://localhost:3000') >= 0) return 'http://localhost:6543'
  if (window.location.href.indexOf('https://dashboard.prod.tools.citopia.fr') >= 0) return 'https://api-dashboard.prod.tools.citopia.fr'
  return 'https://api-dashboard.dev.tools.citopia.fr'
}

export async function getAppsWithClient(
  navigate: NavigateFunction,
  searchValue: string,
  etat: string,
  prodVersion: string,
  testVersion: string,
  rangeDate: DateRange | undefined,
  isShowHide: boolean,
) {
  const token = localStorage.getItem('token')

  const headers: HeadersInit = {}

  if (token) {
    headers.authorization = token
  }
  const params = new URLSearchParams()
  if (searchValue.length > 0) params.append('search', searchValue)
  if (etat.length > 0) params.append('etat', etat)
  if (prodVersion.length > 0) params.append('prodVersion', prodVersion)
  if (rangeDate?.startDate) params.append('startDate', format(rangeDate?.startDate, 'MM/dd/yyyy'))
  if (testVersion.length > 0) params.append('testVersion', testVersion)
  if (rangeDate?.endDate) params.append('endDate', format(rangeDate?.endDate, 'MM/dd/yyyy'))
  if (isShowHide === true) params.append('isShowHide', 'true')
  const clientsResponse = await fetch(`${getApiUrl()}/clients/apps?${params.toString()}`, {
    headers,
  })
  if (clientsResponse.status === 401) {
    navigate('/login')
  }
  return clientsResponse.json()
}

export async function getAllTestAppsVersions() {
  const token = localStorage.getItem('token')

  const headers: HeadersInit = {}

  if (token) {
    headers.authorization = token
  }
  const testVersionsResponse = await fetch(`${getApiUrl()}/apps/versions/test`, {
    headers,
  })
  return testVersionsResponse.json()
}

export async function getAllProdAppsVersions() {
  const token = localStorage.getItem('token')

  const headers: HeadersInit = {}

  if (token) {
    headers.authorization = token
  }
  const prodVersionsResponse = await fetch(`${getApiUrl()}/apps/versions/prod`, {
    headers,
  })
  return prodVersionsResponse.json()
}

export async function getAppDetail(appId: string | undefined) {
  const token = localStorage.getItem('token')

  const headers: HeadersInit = {}

  if (token) {
    headers.authorization = token
  }

  const appDetail = await fetch(`${getApiUrl()}/client/app/${appId}`, {
    headers,
  })
  return appDetail.json()
}

export async function updateAppData(
  appId: string,
  subscriptionDate: string | undefined,
  identifiant: string | undefined,
  preReleaseVersion: string | undefined,
  preReleaseBuild: string | undefined,
  prodBuild: string | undefined,
  gouvCertificate: string | undefined,
  googleSignIn: string | undefined,
  isHide: boolean | undefined,
  certificates: number | undefined,
) {
  const token = localStorage.getItem('token')

  const headers: HeadersInit = {}

  if (token) {
    headers.authorization = token
  }

  const clientsResponse = await fetch(`${getApiUrl()}/client/app/${appId}/update`, {
    method: 'PATCH',
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      clientIdentifiant: identifiant,
      testVersion: preReleaseVersion,
      testVersionBuild: preReleaseBuild,
      subscriptionDate,
      googleSignIn,
      gouvernementalData: gouvCertificate,
      prodVersionBuild: prodBuild,
      isHide,
      certificates,
    }),
  })

  return clientsResponse
}
