/* eslint-disable global-require */
import { Button, TextField } from '@mui/material'
import * as React from 'react'
import './Login.scss'
import { useNavigate } from 'react-router-dom'
import sha256 from 'crypto-js/sha256'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { getApiUrl } from '../../Api/api'

export function LoginPage() {
  const navigate = useNavigate()
  const [email, setEmail] = useState<undefined | string>(undefined)
  const [password, setPassword] = useState<undefined | string>(undefined)
  const [isWrongCredantials, setIsWrongCredantials] = useState<boolean>(false)

  interface LoginResponse {
    token: string
    id: string
  }

  async function submitLoginForm(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const { email, password } = {
      email: event.currentTarget?.email?.value,
      password: event.currentTarget?.password?.value,
    }
    const hashedPassword = await sha256(password)
    setEmail(email)
    setPassword(hashedPassword.toString())
  }

  const { data, isError, isSuccess } = useQuery<LoginResponse>({
    queryKey: ['apps'],
    queryFn: () =>
      fetch(`${getApiUrl()}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
        }),
      }).then(response => response.json()),
    enabled: !!email && !!password,
  })

  React.useEffect(() => {
    setIsWrongCredantials(false)
    if (isError) {
      throw new Error('Une erreur est survenue lors de la requete')
    }

    if (isSuccess) {
      if (data.token) {
        localStorage.setItem('token', data.token)
        navigate('/')
      } else {
        setIsWrongCredantials(true)
      }
    }
  }, [isError, isSuccess, data, navigate])

  return (
    <div className="pageLoginContainer">
      <div className="LoginFormContainer">
        <div className="LoginFormTitleContainer">
          <h1>Dashboard</h1>
          <img src={require('../../Assets/Images/Logo-Dashboard.png')} alt="logo" />
        </div>
        <div className="LoginFormBodyContainer">
          <form onSubmit={event => submitLoginForm(event)} noValidate id="form" className="loginForm">
            {isWrongCredantials ? (
              <>
                <TextField name="email" id="emailInput" type="email" placeholder="Email" required variant="standard" fullWidth error label="Login ou mot de passe incorrect" />
                <TextField name="password" type="password" placeholder="Mot de passe" required variant="standard" fullWidth error />
              </>
            ) : (
              <>
                <TextField name="email" id="emailInput" type="email" placeholder="Email" required variant="standard" fullWidth />
                <TextField name="password" type="password" placeholder="Mot de passe" required variant="standard" fullWidth />
              </>
            )}

            <Button type="submit" fullWidth variant="contained" color="primary">
              Se connecter
            </Button>
          </form>
        </div>
      </div>
    </div>
  )
}
