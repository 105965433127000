import * as React from 'react'
import { ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material'
import './Navigation.scss'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { MenuItemTypes } from '../../Services/Types/MenuItemTypes'

type NavigationComponentTypes = {
  menuItem: MenuItemTypes
}

export function NavigationComponent({ menuItem }: NavigationComponentTypes) {
  const navigate = useNavigate()

  useEffect(() => {
    menuItem.setSelectedMenuItem(menuItem.navigationItems.filter(item => item.path === window.location.pathname)[0]?.name)
  }, [])

  return (
    <MenuList className="navigationContainer">
      {menuItem.navigationItems.map(item => (
        <MenuItem
          className={`menuItem ${menuItem.selectedMenuItem === item.name && 'active'}`}
          onClick={() => {
            navigate(item.path)
            menuItem.setSelectedMenuItem(item.name)
          }}
          key={item.name}
        >
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText>{item.name}</ListItemText>
        </MenuItem>
      ))}
    </MenuList>
  )
}
