import React from 'react'
import SearchIcon from '@mui/icons-material/Search'

type SearchBarProps = {
  setSearchText: (query: string) => void
}

export default function SearchBar({ setSearchText }: SearchBarProps) {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)
  }

  return (
    <div className="searchInputContainer">
      <SearchIcon className="searchIcon" />
      <input placeholder="Rechercher" className="inputSearch" id="searchInput" onChange={handleInputChange} />
    </div>
  )
}
