import { useLocation } from 'react-router-dom'
import React, { useState } from 'react'

import LeftMenuComponent from '../../Components/LeftMenu/LeftMenu.component'
import { NavigationItem } from '../../Services/Types/MenuItemTypes'
import './Anomalies.scss'
import Anomalies from '../../Components/Anomalies/Anomalies.component'

type AnomaliesPageProps = {
  navigationItems: Array<NavigationItem>
}

function AnomaliesPage({ navigationItems }: AnomaliesPageProps) {
  const [selectedMenuItem, setSelectedMenuItem] = useState('')

  const location = useLocation()
  const pathSegments = location.pathname.split('/')
  const anomalyPlatform = pathSegments[pathSegments.length - 1]

  return (
    <div className="anomaly-container">
      <LeftMenuComponent menuItem={{ selectedMenuItem, setSelectedMenuItem, navigationItems }} />
      <div className="anomaly-content">
        {anomalyPlatform === 'apple' && <Anomalies platform="apple" />}
        {anomalyPlatform === 'google' && <Anomalies platform="google" />}
      </div>
    </div>
  )
}

export default AnomaliesPage
