import { Button, MenuItem, TextField } from '@mui/material'
import React from 'react'
import { Client, FormData } from '../../../Services/Types/ApplicationEditFormTypes'

function SelectClient({
  formData,
  handleClientSelect,
  clients,
  setState,
  closeModal,
}: {
  formData: FormData
  handleClientSelect: (event: React.ChangeEvent<HTMLInputElement>) => void
  clients: Array<Client> | undefined
  setState: (state: 'SelectClient' | 'EditClient' | 'newClient') => void
  closeModal: () => void
}) {
  return (
    <div>
      <TextField
        label="Sélectionnez un client"
        variant="standard"
        fullWidth
        name="clientName"
        value={formData.clientName}
        onChange={handleClientSelect}
        select
        SelectProps={{
          MenuProps: {
            style: { maxHeight: 300 },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          },
        }}
      >
        {clients?.map((client: { id: string; name: string }) => (
          <MenuItem key={client.id} value={client.name}>
            {client.name}
          </MenuItem>
        ))}
      </TextField>
      <div className="button-group">
        <Button variant="outlined" color="primary" onClick={closeModal}>
          Annuler
        </Button>
        <Button variant="contained" color="primary" onClick={() => setState('newClient')}>
          Nouveau client
        </Button>
      </div>
    </div>
  )
}

export default SelectClient
