import * as React from 'react'
import { styled } from '@mui/material/styles'
import dayjs from 'dayjs'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Chip } from '@mui/material'
import { blueGrey, lightBlue } from '@mui/material/colors'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import TableSortLabel from '@mui/material/TableSortLabel'
import { AppDataTypes } from '../../../Services/Types/AppsDataTypes'
import './ClientListTable.scss'
import { getApiUrl } from '../../../Api/api'

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: lightBlue[50],
    color: blueGrey[500],
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

type ClientListTableComponentType = {
  appsData: Array<AppDataTypes> | undefined
  refetch: () => void
}

type OrderByType = keyof AppDataTypes | ''

export default function ClientListTableComponent({ appsData, refetch }: ClientListTableComponentType) {
  const navigate = useNavigate()
  const [orderBy, setOrderBy] = useState<OrderByType>('')
  const [order, setOrder] = useState<'asc' | 'desc'>('asc')
  const [filter] = useState<string>('')

  const translationMap = {
    READY_FOR_SALE: 'Prêt à la vente',
    NOT_ON_STORE: 'Non disponible',
    PREPARE_FOR_SUBMISSION: 'Préparation pour soumission',
    DELETED: 'Supprimé',
    REPLACED_WITH_NEW_VERSION: 'Remplacé par une nouvelle version',
    REMOVED_FROM_SALE: 'Retiré de la vente',
    DEVELOPER_REMOVED_FROM_SALE: 'Retiré de la vente par le développeur',
  }

  const handleSort = (columnId: OrderByType) => {
    if (orderBy === columnId) {
      setOrder(order === 'asc' ? 'desc' : 'asc')
    } else {
      setOrderBy(columnId)
      setOrder('asc')
    }
  }

  function compareVersions(versionA: string, versionB: string) {
    const partsA = versionA.split('.').map(Number)
    const partsB = versionB.split('.').map(Number)

    for (let i = 0; i < Math.max(partsA.length, partsB.length); i++) {
      const partA = partsA[i] || 0
      const partB = partsB[i] || 0

      if (partA < partB) {
        return -1
      }
      if (partA > partB) {
        return 1
      }
    }

    return 0
  }

  function compareDates(dateA: string, dateB: string) {
    const parsedDateA = dayjs(dateA)
    const parsedDateB = dayjs(dateB)

    if (dateA === '---' && dateB === '---') {
      return 0
    }

    if (dateA === '---') {
      return 1
    }

    if (dateB === '---') {
      return -1
    }

    if (parsedDateA.isBefore(parsedDateB)) {
      return -1
    }

    if (parsedDateA.isAfter(parsedDateB)) {
      return 1
    }

    return 0
  }

  let sortedData = Array.isArray(appsData) ? appsData : []

  sortedData = sortedData?.sort((a, b) => {
    const valueA = ((a as unknown as Record<string, unknown>)[orderBy] as string) || ''
    const valueB = ((b as unknown as Record<string, unknown>)[orderBy] as string) || ''

    if (order === 'asc') {
      if (orderBy === 'prodVersion' || orderBy === 'preReleaseVersion') {
        return compareVersions(valueA, valueB)
      }
      if (orderBy === 'updateDate') {
        return compareDates(valueA, valueB)
      }
      return valueA.localeCompare(valueB)
    }
    if (orderBy === 'prodVersion' || orderBy === 'preReleaseVersion') {
      return compareVersions(valueB, valueA)
    }
    if (orderBy === 'updateDate') {
      return compareDates(valueB, valueA)
    }
    return valueB.localeCompare(valueA)
  })

  if (filter) {
    sortedData = sortedData?.filter(row => {
      const columnsToFilter: Array<keyof AppDataTypes> = ['client', 'appName'] // Ajoutez d'autres colonnes ici si nécessaire
      const rowValues = columnsToFilter.map(column => row[column]?.toLowerCase() || '')
      return rowValues.some(value => value.includes(filter.toLowerCase()))
    })
  }

  function updateHandler() {
    fetch(`${getApiUrl()}/update`).then(() => refetch())
  }

  return (
    <TableContainer component={Paper} className="clientTableContainer">
      <div className="table--header-action">
        <p className="appCounter">{sortedData?.length} applications</p>
        <button className="update-btn" type="button" onClick={updateHandler}>
          Mettre à jour
        </button>
      </div>

      <Table sx={{ minWidth: 700 }} aria-label="customized table" className="clientTable">
        <TableHead className="tableHead">
          <TableRow>
            <StyledTableCell align="left">
              <TableSortLabel active={orderBy === 'client'} direction={orderBy === 'client' ? order : 'asc'} onClick={() => handleSort('client')}>
                Client
              </TableSortLabel>
            </StyledTableCell>
            <StyledTableCell align="left">
              <TableSortLabel active={orderBy === 'appName'} direction={orderBy === 'appName' ? order : 'asc'} onClick={() => handleSort('appName')}>
                Application
              </TableSortLabel>
            </StyledTableCell>
            <StyledTableCell align="center">
              <TableSortLabel active={orderBy === 'platforme'} direction={orderBy === 'platforme' ? order : 'asc'} onClick={() => handleSort('platforme')}>
                Platforme
              </TableSortLabel>
            </StyledTableCell>
            <StyledTableCell align="center">
              <TableSortLabel active={orderBy === 'etat'} direction={orderBy === 'etat' ? order : 'asc'} onClick={() => handleSort('etat')}>
                Etat
              </TableSortLabel>
            </StyledTableCell>
            <StyledTableCell align="center">
              <TableSortLabel active={orderBy === 'prodVersionState'} direction={orderBy === 'prodVersionState' ? order : 'asc'} onClick={() => handleSort('prodVersionState')}>
                Statut de l'app - PROD
              </TableSortLabel>
            </StyledTableCell>
            <StyledTableCell align="left">
              <TableSortLabel active={orderBy === 'prodVersion'} direction={orderBy === 'prodVersion' ? order : 'asc'} onClick={() => handleSort('prodVersion')}>
                Version PROD
              </TableSortLabel>
            </StyledTableCell>
            <StyledTableCell align="left">
              <TableSortLabel active={orderBy === 'preReleaseVersion'} direction={orderBy === 'preReleaseVersion' ? order : 'asc'} onClick={() => handleSort('preReleaseVersion')}>
                Version TEST
              </TableSortLabel>
            </StyledTableCell>
            <StyledTableCell align="left">
              <TableSortLabel active={orderBy === 'updateDate'} direction={orderBy === 'updateDate' ? order : 'asc'} onClick={() => handleSort('updateDate')}>
                Date de déploiement
              </TableSortLabel>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody className="tableBody">
          {Array.isArray(sortedData) &&
            sortedData?.map((row, key) => {
              const styledUpdatedDate = dayjs(row.updateDate).format('DD/MM/YYYY')
              return (
                <StyledTableRow key={`row ${row.appName + key}`} onClick={() => navigate(`/app/${row.id}`)}>
                  <StyledTableCell align="left">{row.client || '--'}</StyledTableCell>
                  <StyledTableCell align="left">{row.appName || '--'}</StyledTableCell>
                  <StyledTableCell align="center">{row.platforme || '--'}</StyledTableCell>
                  <StyledTableCell align="left">
                    <Chip
                      label={row.etat}
                      color={row.etat === 'OK' ? 'success' : 'error'}
                      size="small"
                      sx={{
                        borderRadius: '5px',
                        opacity: '0.8',
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell className="greenLine" align="center">
                    {translationMap[row.prodVersionState as keyof typeof translationMap] || '--'}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.prodVersion || '--'}</StyledTableCell>
                  <StyledTableCell align="left">{row.preReleaseVersion || '--'}</StyledTableCell>
                  <StyledTableCell align="left">{styledUpdatedDate !== 'Invalid Date' ? styledUpdatedDate : '--'}</StyledTableCell>
                </StyledTableRow>
              )
            })}

          {sortedData?.length === 0 && (
            <StyledTableRow>
              <StyledTableCell align="center" className="noResult">
                Aucun résultat
              </StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
