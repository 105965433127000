import React, { useState, useCallback, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Modal } from '@mui/material'
import './ApplicationAddForm.scss'
import { getApiUrl } from '../../Api/api'
import SelectClient from './SelectClient/SelectClient.component'
import { Client, FormData } from '../../Services/Types/ApplicationEditFormTypes'
import NewClient from './NewClient/NewClient.component'
import EditClient from './EditClient/EditClient.component'

export type ApplicationAddFormProps = {
  closeModal: () => void
  showModal: boolean
}

function ApplicationEditForm({ closeModal, showModal }: ApplicationAddFormProps) {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    p8File: '',
    iosKeyId: '',
    iosIssuerId: '',
    identifier: '',
    clientExisting: false,
    clientName: '',
    clientId: '',
  })
  const [state, setState] = useState<'SelectClient' | 'EditClient' | 'newClient'>('SelectClient')

  useEffect(() => {
    if (state !== 'EditClient') {
      setFormData({
        name: '',
        p8File: '',
        iosKeyId: '',
        iosIssuerId: '',
        identifier: '',
        clientExisting: false,
        clientName: '',
        clientId: '',
      })
    }
  }, [state])

  const token = localStorage.getItem('token')
  const headers: HeadersInit = React.useMemo(() => {
    const headers: HeadersInit = {}
    if (token) {
      headers.Authorization = token
    }
    return headers
  }, [token])

  const fetchClients = useCallback(async () => {
    const response = await fetch(`${getApiUrl()}/clients`, {
      method: 'GET',
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
    })
    const data = await response.json()
    return data
  }, [headers])

  const { data: clients, refetch } = useQuery<Array<Client>>({
    queryKey: ['clients'],
    queryFn: fetchClients,
    refetchOnWindowFocus: false,
  })

  const handleClientSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const selectedClient = clients?.find((client: Client) => client.name === value)
    if (selectedClient) {
      setFormData({
        name: '',
        p8File: selectedClient.ios_p8,
        iosKeyId: selectedClient.ios_key_id,
        iosIssuerId: selectedClient.ios_issuerid,
        identifier: '',
        clientExisting: true,
        clientName: selectedClient.name,
        clientId: selectedClient.id,
      })
      setState('EditClient')
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }))
  }

  return (
    <Modal className="modal-container" open={showModal} onClose={closeModal}>
      <div className="form-card">
        {state === 'newClient' ? <h2>Ajouter un client</h2> : <h2>Modifier un client</h2>}
        {state === 'SelectClient' && <SelectClient clients={clients} formData={formData} handleClientSelect={handleClientSelect} setState={setState} closeModal={closeModal} />}
        {state === 'EditClient' && <EditClient headers={headers} formData={formData} setState={setState} handleInputChange={handleInputChange} refetch={refetch} />}
        {state === 'newClient' && <NewClient refetch={refetch} headers={headers} handleInputChange={handleInputChange} formData={formData} setState={setState} />}
      </div>
    </Modal>
  )
}

export default ApplicationEditForm
