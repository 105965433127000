import React, { useState, useEffect } from 'react'
import AppleIcon from '@mui/icons-material/Apple'
import AndroidIcon from '@mui/icons-material/Android'
import { Table, TableBody, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material'
import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { blueGrey, lightBlue } from '@mui/material/colors'
import { useQuery } from '@tanstack/react-query'

import { useNavigate } from 'react-router-dom'
import { HeaderContainerComponent } from '../ApplicationList/HeaderContainer/HeaderContainer.component'
import SearchBar from '../UI/SearchBar/SearchBar.component'
import { AnomalyType } from '../../Services/Types/ApiTypes'
import { getApiUrl } from '../../Api/api'

type AnomaliesProps = {
  platform: string
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: lightBlue[50],
    color: blueGrey[500],
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

function Anomalies({ platform }: AnomaliesProps) {
  const navigate = useNavigate()

  const platformName = platform.charAt(0).toUpperCase() + platform.slice(1)
  const token = localStorage.getItem('token')

  const icon = platformName === 'Apple' ? <AppleIcon /> : <AndroidIcon />

  const headers: HeadersInit = {}

  if (token) {
    headers.Authorization = token
  }

  const anomalies = useQuery<Array<AnomalyType>>({
    queryKey: ['anomalies'],
    queryFn: () =>
      fetch(`${getApiUrl()}/clients/apps/anomalies/${platform === 'apple' ? 'ios' : 'android'}`, {
        method: 'GET',
        headers: {
          ...headers,
          'Content-Type': 'application/json',
        },
      }).then(res => res.json()),
    refetchOnWindowFocus: false,
  })

  const [filteredAnomalies, setFilteredAnomalies] = useState<Array<AnomalyType> | null>(null)
  const [searchText, setSearchText] = useState<string | null>(null)
  const [orderBy, setOrderBy] = useState<keyof AnomalyType | ''>('')
  const [order, setOrder] = useState<'asc' | 'desc'>('asc')

  const handleSort = (columnId: keyof AnomalyType) => {
    if (orderBy === columnId) {
      setOrder(order === 'asc' ? 'desc' : 'asc')
    } else {
      setOrderBy(columnId)
      setOrder('asc')
    }
  }

  const sortAnomalies = (anomalies: Array<AnomalyType>) => {
    const sorted = [...anomalies].sort((a, b) => {
      const valueA = ((a as unknown as Record<string, unknown>)[orderBy] as string) || ''
      const valueB = ((b as unknown as Record<string, unknown>)[orderBy] as string) || ''

      if (order === 'asc') {
        return valueA.localeCompare(valueB)
      }
      return valueB.localeCompare(valueA)
    })

    setFilteredAnomalies(sorted)
  }

  useEffect(() => {
    if (searchText) {
      filterAnomalies(searchText)
    } else if (anomalies.data) {
      sortAnomalies(anomalies.data)
    }
  }, [searchText, platform, anomalies.data, orderBy, order])

  const filterAnomalies = (query: string) => {
    if (anomalies.data) {
      const filtered = anomalies.data.filter(item => item.name.toLowerCase().includes(query.toLowerCase()))
      sortAnomalies(filtered)
    }
  }

  const content = (
    <TableContainer>
      <Table>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>
              <TableSortLabel active={orderBy === 'name'} direction={orderBy === 'name' ? order : 'asc'} onClick={() => handleSort('name')}>
                Client
              </TableSortLabel>
            </StyledTableCell>
            <StyledTableCell>
              <TableSortLabel active={orderBy === 'app_name'} direction={orderBy === 'app_name' ? order : 'asc'} onClick={() => handleSort('app_name')}>
                Application
              </TableSortLabel>
            </StyledTableCell>
            <StyledTableCell>
              <TableSortLabel active={orderBy === 'anomaly_name'} direction={orderBy === 'anomaly_name' ? order : 'asc'} onClick={() => handleSort('anomaly_name')}>
                Anomalie
              </TableSortLabel>
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody className="tableBody">
          {filteredAnomalies?.map(data => (
            <StyledTableRow key={data.id} onClick={() => navigate(`/app/${data.application_id}`)}>
              <StyledTableCell>{data.name ? data.name : '----'}</StyledTableCell>
              <StyledTableCell>{data.app_name ? data.app_name : '----'}</StyledTableCell>
              <StyledTableCell>{data.anomaly_name ? data.anomaly_name : '----'}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )

  return (
    <div className="container">
      <HeaderContainerComponent>
        <>
          <div>{icon}</div>
          <p>Anomalies {platformName}</p>
        </>
      </HeaderContainerComponent>
      <SearchBar setSearchText={setSearchText} />
      {content}
    </div>
  )
}

export default Anomalies
