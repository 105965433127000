import { Button, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { FormData } from '../../../Services/Types/ApplicationEditFormTypes'
import { getApiUrl } from '../../../Api/api'

function EditClient({
  formData,
  setState,
  handleInputChange,
  headers,
  refetch,
}: {
  formData: FormData
  setState: (state: 'SelectClient' | 'EditClient' | 'newClient') => void
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  headers: { [key: string]: string }
  refetch: () => void
}) {
  const [isAddAndroidApp, setIsAddAndroidApp] = React.useState<boolean>(false)
  const [androidApp, setAndroidApp] = React.useState<Array<{ app_name: string; id: string; identifier: string }> | null>(null)
  const [appIdentifier, setAppIdentifier] = React.useState<string>('')

  async function handleDeleteClient() {
    await fetch(`${getApiUrl()}/client/${formData.clientId}/delete`, {
      method: 'DELETE',
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
    })
    refetch()
    setState('SelectClient')
  }

  function updateAndroidApp() {
    fetch(`${getApiUrl()}/client/${formData.clientId}/apps`, {
      method: 'GET',
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
    }).then(result => {
      result.json().then(data => setAndroidApp(data))
    })
  }

  function handleDeleteAndroidApp(id: string) {
    fetch(`${getApiUrl()}/app/${id}/delete`, {
      method: 'DELETE',
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
    }).then(() => {
      updateAndroidApp()
    })
  }

  function handleAddAndroidApp() {
    fetch(`${getApiUrl()}/client/${formData.clientId}/app/add`, {
      method: 'POST',
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ identifier: appIdentifier }),
    }).then(() => {
      updateAndroidApp()
      setIsAddAndroidApp(false)
    })
  }

  useEffect(() => {
    updateAndroidApp()
  }, [formData.clientId])

  return (
    <div>
      <div>
        <TextField label="Nom du client" variant="standard" fullWidth name="name" value={formData.clientName} />
        <TextField
          value={formData.p8File}
          label="fichier p8"
          variant="standard"
          fullWidth
          name="p8File"
          multiline
          maxRows={2}
          id="outlined-multiline-flexible"
          InputLabelProps={{ shrink: !!formData.p8File }}
          onChange={handleInputChange}
        />
        <TextField label="ios key id" variant="standard" fullWidth name="iosKeyId" value={formData.iosKeyId} onChange={handleInputChange} />
        <TextField label="ios issuerid" variant="standard" fullWidth name="iosIssuerId" value={formData.iosIssuerId} onChange={handleInputChange} />
      </div>
      <h3>Applications android :</h3>
      <Button variant="outlined" size="small" onClick={() => setIsAddAndroidApp(!isAddAndroidApp)}>
        Ajouter une app
      </Button>
      {isAddAndroidApp && (
        <div className="addAndroidApp">
          <TextField
            label="Identifier"
            variant="standard"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              width: '100%',
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAppIdentifier(event.target.value)}
          />
          <Button
            variant="contained"
            size="small"
            sx={{
              marginTop: '0.5em',
              marginBottom: '0.5em',
              paddingLeft: '2em',
              paddingRight: '2em',
            }}
            onClick={() => {
              handleAddAndroidApp()
            }}
          >
            Ajouter
          </Button>
        </div>
      )}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Application</TableCell>
            <TableCell>Identifier</TableCell>
            <TableCell>Supprimer</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {androidApp &&
            androidApp?.map((app: { app_name: string; id: string; identifier: string }) => (
              <TableRow>
                <TableCell>{app.app_name}</TableCell>
                <TableCell
                  sx={{
                    maxWidth: '10em',
                    textOverflow: 'ellipsis',
                    overflowY: 'hidden',
                  }}
                >
                  {app.identifier}
                </TableCell>
                <TableCell align="center" onClick={() => handleDeleteAndroidApp(app.id)}>
                  <HighlightOffIcon />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>

      <div className="button-group">
        <Button variant="outlined" color="primary" onClick={() => setState('SelectClient')}>
          retour
        </Button>
        <Button variant="contained" color="error" onClick={() => handleDeleteClient()}>
          Supprimer client
        </Button>
        <Button variant="contained" color="success" type="submit">
          Valider
        </Button>
      </div>
    </div>
  )
}

export default EditClient
