import * as React from 'react'
import { HeaderContainerComponent } from '../HeaderContainer/HeaderContainer.component'
import './Container.scss'
import ClientListTableComponent from '../ClientListTable/ClientListTable.component'
import { MenuItemTypes } from '../../../Services/Types/MenuItemTypes'
import { SearchComponent } from '../Search/Search.component'
import { useClientApps } from '../../../hooks/useClientApps'

type ContainerComponentTypes = {
  menuItem: MenuItemTypes
}
export function ContainerComponent({ menuItem }: ContainerComponentTypes) {
  const { apps, refetch } = useClientApps()
  const currentMenuItem = menuItem.navigationItems.find(element => element.name === menuItem.selectedMenuItem)

  return (
    <div className="container">
      <HeaderContainerComponent>
        <>
          <div className="icon">{currentMenuItem?.icon}</div>
          <p>{currentMenuItem?.name}</p>
        </>
      </HeaderContainerComponent>
      <SearchComponent />
      <ClientListTableComponent refetch={refetch} appsData={apps} />
    </div>
  )
}
