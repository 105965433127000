import * as React from 'react'
import './Search.scss'
import SearchIcon from '@mui/icons-material/Search'
import { Filters } from '../Filters/Filters.component'
import { useClientApps } from '../../../hooks/useClientApps'

export function SearchComponent() {
  const [searchInputValue, setSearchInputValue] = React.useState('')
  const {
    prodVersions,
    testVersions,
    etat,
    prodVersion,
    testVersion,
    setEtat,
    setProdVersion,
    setTestVersion,
    refetch,
    setSearchValue,
    setRangeDate,
    rangeDate,
    setIsShowHide,
    isShowHide,
  } = useClientApps()

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    setSearchInputValue(inputValue)
    setSearchValue(inputValue)
  }

  return (
    <div className="searchInputContainer">
      <SearchIcon className="searchIcon" />
      <input placeholder="Rechercher" className="inputSearch" id="searchInput" value={searchInputValue} onChange={handleInputChange} />
      <Filters
        prodVersions={prodVersions}
        testVersions={testVersions}
        etat={etat}
        prodVersion={prodVersion}
        testVersion={testVersion}
        setEtat={setEtat}
        setProdVersion={setProdVersion}
        setTestVersion={setTestVersion}
        refetch={refetch}
        setRangeDate={setRangeDate}
        dateRange={rangeDate}
        setIsShowHide={setIsShowHide}
        isShowHide={isShowHide}
      />
    </div>
  )
}
