import * as React from 'react'
import FilterListIcon from '@mui/icons-material/FilterList'
import './Filters.scss'
import { Button, FormControl, Input, InputLabel, MenuItem, Modal, Select, Switch } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { format } from 'date-fns'
import { DateRange } from 'mui-daterange-picker'
import { DateRangePickerFilter } from '../DateRangePicker/DateRangePicker'

type FilterProps = {
  prodVersions: string[] | undefined
  testVersions: string[] | undefined
  etat: string
  prodVersion: string
  testVersion: string
  setEtat: (etat: string) => void
  setProdVersion: (prodVersion: string) => void
  setTestVersion: (testVersion: string) => void
  refetch: () => void
  setRangeDate: (range: DateRange) => void
  dateRange: DateRange | undefined
  setIsShowHide: (isShowHide: boolean) => void
  isShowHide: boolean
}

export function Filters({
  prodVersions,
  testVersions,
  etat,
  prodVersion,
  testVersion,
  setEtat,
  setProdVersion,
  setTestVersion,
  refetch,
  setRangeDate,
  dateRange,
  setIsShowHide,
  isShowHide,
}: FilterProps) {
  const [open, setOpen] = React.useState(false)
  const [openDatePicker, setOpenDatePicker] = React.useState(false)

  const toggleDatePicker = () => setOpenDatePicker(!openDatePicker)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  function handleValidateFilters(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    setEtat(event.currentTarget?.etat.value)
    setProdVersion(event.currentTarget?.prodVersion.value)
    setTestVersion(event.currentTarget?.testVersion.value)
    setIsShowHide(event.currentTarget?.isShowHideApp.checked)
    refetch()
    handleClose()
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Button onClick={handleOpen} className="filterButton">
        <FilterListIcon />
      </Button>
      <Modal open={open} onClose={handleClose} className="ModalContainerBox" aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <div className="modalContainer">
          <h3>Filtres</h3>
          <form onSubmit={event => handleValidateFilters(event)}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Etat</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant="standard"
                label="etat"
                name="etat"
                value={etat}
                onChange={event => setEtat(event.target.value)}
              >
                <MenuItem value="">Tous</MenuItem>
                <MenuItem value="OK">Prêt</MenuItem>
                <MenuItem value="KO">KO</MenuItem>
              </Select>
            </FormControl>
            <div className="versionFormContainer">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Version en prod</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  variant="standard"
                  label="prodVersion"
                  name="prodVersion"
                  value={prodVersion}
                  onChange={event => setProdVersion(event.target.value)}
                >
                  <MenuItem value="">Tous</MenuItem>
                  {Array.isArray(prodVersions) &&
                    prodVersions?.map((version: string) => (
                      <MenuItem value={version} key={`prodVersion ${version}`}>
                        {version}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Version en test</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  variant="standard"
                  label="testVersion"
                  name="testVersion"
                  value={testVersion}
                  onChange={event => setTestVersion(event.target.value)}
                >
                  <MenuItem value="">Tous</MenuItem>
                  {Array.isArray(testVersions) &&
                    testVersions?.map((version: string) => (
                      <MenuItem value={version} key={`testVersion ${version}`}>
                        {version}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <DateRangePickerFilter setDateRange={setRangeDate} toggle={toggleDatePicker} open={openDatePicker} />
            <button className="dateRangePickerButtonContainer" onClick={toggleDatePicker} type="button">
              <div>
                <InputLabel>Date de début</InputLabel>
                <Input value={dateRange?.startDate && format(dateRange?.startDate, 'dd/MM/yyyy')} />
              </div>
              <div>
                <InputLabel>Date de fin</InputLabel>
                <Input value={dateRange?.endDate && format(dateRange?.endDate, 'dd/MM/yyyy')} />
              </div>
            </button>
            <div className="switchContainer">
              <p>Afficher les applications cachées ?</p>
              <Switch name="isShowHideApp" checked={isShowHide} onChange={event => setIsShowHide(event.target.checked)} />
            </div>
            <Button type="submit" variant="contained" className="validationButton">
              Valider
            </Button>
          </form>
        </div>
      </Modal>
    </LocalizationProvider>
  )
}
