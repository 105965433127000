import { useQuery } from '@tanstack/react-query'
import * as React from 'react'
import { useDebounce } from 'use-debounce'
import { useNavigate } from 'react-router-dom'
import { DateRange } from 'mui-daterange-picker'
import { getAppsWithClient, getAllProdAppsVersions, getAllTestAppsVersions } from '../Api/api'
import { AppDataTypes } from '../Services/Types/AppsDataTypes'

export const useClientApps = () => {
  const navigate = useNavigate()
  const [searchValue, setSearchValue] = React.useState<string>('')
  const [etat, setEtat] = React.useState<string>('')
  const [prodVersion, setProdVersion] = React.useState<string>('')
  const [testVersion, setTestVersion] = React.useState<string>('')
  const [rangeDate, setRangeDate] = React.useState<DateRange | undefined>(undefined)
  const [isShowHide, setIsShowHide] = React.useState<boolean>(false)

  const debouncedSearchText = useDebounce(searchValue, 200)

  const apps = useQuery<Array<AppDataTypes>>({
    queryKey: ['apps'],
    queryFn: () => getAppsWithClient(navigate, debouncedSearchText[0], etat, prodVersion, testVersion, rangeDate, isShowHide),
    refetchOnWindowFocus: false,
  })
  const prodVersions = useQuery<Array<string>>({
    queryKey: ['prodVersions'],
    queryFn: () => getAllProdAppsVersions(),
    refetchOnWindowFocus: false,
  })
  const testVersions = useQuery<Array<string>>({
    queryKey: ['testVersions'],
    queryFn: () => getAllTestAppsVersions(),
    refetchOnWindowFocus: false,
  })

  React.useEffect(() => {
    apps.refetch()
  }, [debouncedSearchText[0]])

  return {
    refetch: apps.refetch,
    setSearchValue,
    apps: apps.data,
    setEtat,
    setTestVersion,
    setProdVersion,
    etat,
    prodVersion,
    testVersion,
    prodVersions: prodVersions.data,
    testVersions: testVersions.data,
    setRangeDate,
    rangeDate,
    setIsShowHide,
    isShowHide,
  }
}
