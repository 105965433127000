import * as React from 'react'
import Fab from '@mui/material/Fab'
import Modal from '@mui/material/Modal'
import EditIcon from '@mui/icons-material/Edit'
import './ApplicationList.scss'
import LeftMenuComponent from '../../Components/LeftMenu/LeftMenu.component'
import { ContainerComponent } from '../../Components/ApplicationList/Container/Container.component'
import { NavigationItem } from '../../Services/Types/MenuItemTypes'
import ApplicationEditForm from '../../Components/ApplicationEditForm/ApplicationEditForm.component'

export type ApplicationListPageProps = {
  navigationItems: Array<NavigationItem>
}
export function ApplicationListPage({ navigationItems }: ApplicationListPageProps) {
  const [selectedMenuItem, setSelectedMenuItem] = React.useState('')
  const [showModal, setShowModal] = React.useState(false)
  const [buttonTransformed, setButtonTransformed] = React.useState(false)

  const toggleModal = () => {
    setShowModal(!showModal)
    setButtonTransformed(!buttonTransformed)
  }

  const closeModal = () => {
    setShowModal(false)
    setButtonTransformed(false)
  }

  return (
    <div className="applicationContainer">
      <LeftMenuComponent menuItem={{ selectedMenuItem, setSelectedMenuItem, navigationItems }} />
      <ContainerComponent menuItem={{ selectedMenuItem, setSelectedMenuItem, navigationItems }} />

      <Fab className={buttonTransformed ? 'add-btn transformed' : 'add-btn'} color="primary" onClick={toggleModal} disabled={buttonTransformed}>
        <EditIcon />
      </Fab>
      <Modal open={showModal} onClose={closeModal}>
        <div className="modal-content">
          <ApplicationEditForm closeModal={closeModal} showModal={showModal} />
        </div>
      </Modal>
    </div>
  )
}
