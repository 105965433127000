import React from 'react'
import { DateRangePicker, DateRange } from 'mui-daterange-picker'
import './DateRangePicker.scss'

type Props = {
  setDateRange: (range: DateRange) => void
  toggle: () => void
  open: boolean
}

export function DateRangePickerFilter({ setDateRange, toggle, open }: Props) {
  return (
    <div className={`dateRangePickerContainer ${!open && 'display-none'}`}>
      <DateRangePicker
        open={open}
        toggle={toggle}
        onChange={range => {
          setDateRange(range)
          toggle()
        }}
      />
    </div>
  )
}
