import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import RuleIcon from '@mui/icons-material/Rule'
import AppleIcon from '@mui/icons-material/Apple'
import AndroidIcon from '@mui/icons-material/Android'
import { LoginPage } from './Pages/LoginPage/Login.page'
import { ApplicationListPage } from './Pages/ApplicationList/ApplicationList.page'
import { ApplicationDetail } from './Pages/ApplicationDetail/ApplicationDetail.page'
import AnomaliesPage from './Pages/AnomaliesPage/Anomalies.page'

const navigationItems = [
  {
    path: '/',
    name: 'Liste des applications',
    icon: <RuleIcon fontSize="small" />,
  },
  {
    path: '/anomalies/apple',
    name: 'Anomalies Apple',
    icon: <AppleIcon fontSize="small" />,
  },
  {
    path: '/anomalies/google',
    name: 'Anomalies Google',
    icon: <AndroidIcon fontSize="small" />,
  },
]
export const router = createBrowserRouter([
  {
    path: 'login',
    element: <LoginPage />,
  },
  {
    path: '/',
    element: <ApplicationListPage navigationItems={navigationItems} />,
  },
  {
    path: '/app/:id',
    element: <ApplicationDetail navigationItems={navigationItems} />,
  },
  {
    path: '/anomalies/apple',
    element: <AnomaliesPage navigationItems={navigationItems} />,
  },
  {
    path: '/anomalies/google',
    element: <AnomaliesPage navigationItems={navigationItems} />,
  },
])

const client = new QueryClient()

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <QueryClientProvider client={client}>
    <RouterProvider router={router} />
  </QueryClientProvider>,
)
