/* eslint-disable global-require */
import * as React from 'react'
import './LeftMenu.scss'
import { NavigationComponent } from '../Navigation/Navigation.component'
import { MenuItemTypes } from '../../Services/Types/MenuItemTypes'

type LeftMenuComponentTypes = {
  menuItem: MenuItemTypes
}
function LeftMenuComponent({ menuItem }: LeftMenuComponentTypes) {
  return (
    <div className="menuContainer">
      <div className="logoContainer">
        <img src={require('../../Assets/Images/LogoDashboard.svg').default} alt="logo dashboard" />
        <p>Dashboard</p>
      </div>
      <div className="navigationComponent">
        <NavigationComponent menuItem={menuItem} />
      </div>
    </div>
  )
}

export default LeftMenuComponent
