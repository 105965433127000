import * as React from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import AppleIcon from '@mui/icons-material/Apple'
import ShopIcon from '@mui/icons-material/Shop'
import { useNavigate, useParams } from 'react-router-dom'
import { Chip } from '@mui/material'
import LeftMenuComponent from '../../Components/LeftMenu/LeftMenu.component'
import { NavigationItem } from '../../Services/Types/MenuItemTypes'
import { HeaderContainerComponent } from '../../Components/ApplicationList/HeaderContainer/HeaderContainer.component'
import './ApplicationDetail.scss'
import { getAppDetail, updateAppData } from '../../Api/api'
import { AppDetailType } from '../../Services/Types/AppsDataTypes'
import { AppleDetailContainer } from '../../Components/ApplicationDetail/AppleDetailContainert/AppleDetailContainert'
import { GoogleDetailContainer } from '../../Components/ApplicationDetail/GoogleDetailContainert/GoogleDetailContainer'

export type AppleApplicationDetailProps = {
  navigationItems: Array<NavigationItem>
}
export function ApplicationDetail({ navigationItems }: AppleApplicationDetailProps) {
  const navigate = useNavigate()
  const { id } = useParams()
  const [data, setData] = React.useState<AppDetailType | undefined>(undefined)
  const [subscriptionDate, setSubscriptionDate] = React.useState<string | undefined>(undefined)
  const [identifiant, setIdentifiant] = React.useState<string | undefined>(undefined)
  const [preReleaseVersion, setPreReleaseVersion] = React.useState<string | undefined>(undefined)
  const [preReleaseBuild, setPreReleaseBuild] = React.useState<string | undefined>(undefined)
  const [prodBuild, setProdBuild] = React.useState<string | undefined>(undefined)
  const [gouvCertificate, setGouvCertificate] = React.useState<string | undefined>(undefined)
  const [googleSignIn, setGoogleSignIn] = React.useState<string | undefined>(undefined)
  const [isHide, setIsHide] = React.useState<boolean>(false)
  const [certificates, setCertificates] = React.useState<number | undefined>(undefined)

  React.useEffect(() => {
    getAppDetail(id).then(data => {
      setData(data)
    })
  }, [])

  function handleClickUpdate(
    subscriptionDate: string | undefined,
    identifiant: string | undefined,
    preReleaseVersion: string | undefined,
    preReleaseBuild: string | undefined,
    prodBuild: string | undefined,
    gouvCertificate: string | undefined,
    googleSignIn: string | undefined,
    isHide: boolean | undefined,
  ) {
    if (id) updateAppData(id, subscriptionDate, identifiant, preReleaseVersion, preReleaseBuild, prodBuild, gouvCertificate, googleSignIn, isHide, certificates)
  }

  return (
    <div className="appleApplicationDetailPageContainer">
      <LeftMenuComponent menuItem={{ selectedMenuItem: '', setSelectedMenuItem: () => {}, navigationItems }} />
      <div className="appleApplicationDetailContentContainer">
        <HeaderContainerComponent>
          <>
            <div className="headerDetailContainer">
              <button type="button" onClick={() => navigate('/')}>
                <ArrowBackIcon />
              </button>
              <p className="appName">{data?.app_name}</p>
              <p className="nameSeparator">-</p>
              <p>{data?.name}</p>
              <div className="appleContainer">
                {data?.platforme === 'android' ? <ShopIcon /> : <AppleIcon />}
                <p className="appleText">{data?.platforme === 'android' ? 'Android' : 'Apple'}</p>
              </div>
            </div>
            <Chip
              label={data?.etat}
              color={data?.etat === 'OK' ? 'success' : 'error'}
              size="small"
              sx={{
                borderRadius: '5px',
                opacity: '0.8',
              }}
            />
          </>
        </HeaderContainerComponent>
        {data?.platforme === 'android' ? (
          <GoogleDetailContainer
            appDetail={data}
            editData={{ setGouvCertificate, setPreReleaseVersion, setPreReleaseBuild, setProdBuild, setGoogleSignIn, setIdentifiant, setIsHide }}
            handleClickUpdate={() => handleClickUpdate(subscriptionDate, identifiant, preReleaseVersion, preReleaseBuild, prodBuild, gouvCertificate, googleSignIn, isHide)}
          />
        ) : (
          <AppleDetailContainer
            appDetail={data}
            editData={{ setIdentifiant, setSubscriptionDate, setIsHide, setCertificates }}
            handleClickUpdate={() => handleClickUpdate(subscriptionDate, identifiant, preReleaseVersion, preReleaseBuild, prodBuild, gouvCertificate, googleSignIn, isHide)}
          />
        )}
      </div>
    </div>
  )
}
